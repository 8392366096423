import {
	mdiEye, mdiEyeOff, mdiKeyboardSettings, mdiMagnify, mdiPhone, mdiStarSettings,
} from "@mdi/js";
import { mapState } from "vuex";

import {
	decryptData, generateData, generateHeader, handleFileSize, handleFileType, onWindowResize,
} from "misc";

const allowedImgType = ["image/png", "image/jpeg", "image/jpg"];
const bannerImageHeight = 648;
const bannerImageWidth = 1250;

export const baseMixins = {
	computed: mapState({
		token: (state) => state.auth.token,
	}),
	data() {
		return {
			snackbar: {
				value: false,
				text: null,
				timeout: 2000,
				color: "primary",
			},
			icons: {
				mdiEye,
				mdiEyeOff,
				mdiPhone,
				mdiMagnify,
				mdiKeyboardSettings,
				mdiStarSettings,
			},
			isMobileOnly: this.$isMobileOnly,
			isMobile: this.$isMobile,
			theme: this.$vuetify.theme.dark ? "dark" : "light",
		};
	},
	methods: {
		initPrimary() {
			this.onResize();
		},
		onResize() {
			[this.isMobile, this.isMobileOnly] = onWindowResize(
				this.isMobile,
				this.isMobileOnly,
			);
		},
		openSnackbar(text, color = "primary", timeout = 5000) {
			this.snackbar.value = true;
			this.snackbar.text = text;
			this.snackbar.color = color;
			this.snackbar.timeout = timeout;
		},
		checkConnetion() {
			return this.$online;
		},
		validate(formName) {
			return this.$refs[formName].validate();
		},
		baseCheckBeforeCallApiFunction(formName = null) {
			if (this.checkConnetion()) {
				if (formName !== null) {
					if (this.validate(formName)) {
						return true;
					}
					return false;
				}
				return true;
			}
			return false;
		},
	},
	mounted() {
		this.initPrimary();
	},
};

export const tableMixins = {
	data() {
		return {
			tableInfo: {
				data: {},
				table: {
					selected: [],
					selectKey: null,
					headers: [],
					value: [],
					sort: [],
					sortDesc: [],
					loading: false,
					totalData: 1,
				},
				rowsPerPages: ["10", "20", "30", "50"],
				typingTimer: null,
				doneTypingInterval: 5000,
			},
		};
	},
	mounted() {
		this.initTableMixins();
	},
	methods: {
		initTableMixins() {
			this.initDefaultTableData();
		},
		initDefaultTableData() {
			this.tableInfo.data = {
				count: "10",
				page: 1,
				search: null,
				sort: "",
				sortOrder: "desc",
			};
		},
		// Pagination Method Start
		onChangePage(page) {
			this.tableInfo.data.page = page;
			this.getData();
		},
		// Pagination Method End

		// Per Page Row Count Change Start
		onChangeRowCount(count) {
			this.tableInfo.data.count = count;
			this.tableInfo.data.page = 1;
			this.getData();
		},
		// Per Page Row Count Change End

		// Search Bar value Change Start
		onChangeSearchBar() {
			this.tableInfo.data.page = 1;
			this.getData();
		},
		// Search Bar value Change End

		// Start Table Loading
		startTableLoading() {
			this.tableInfo.table.loading = true;
		},
		stopTableLoading() {
			this.tableInfo.table.loading = false;
		},
		// Stop Table Loading
	},
};

export const imgMixin = {
	data() {
		return {
			img: {
				validation: {
					width: null,
					height: null,
					size: null,
					type: null,
				},
				url: {},
			},
		};
	},
	methods: {
		inputChange(value, index = 0, size = 2000000, verifyDimension = true, cb) {
			if (value) {
				const URL = window.URL || window.webkitURL;
				const file = value;

				this.img.validation.size = file.size;
				this.img.validation.type = file.type;

				const img = new Image();
				const objectUrl = URL.createObjectURL(file);
				img.src = objectUrl;

				const validationProcess = () => {
					const res = this.imgValidation(size, verifyDimension);
					if (res === "true") {
						this.img.url[index] = objectUrl;
						cb(value, index);
					} else {
						this.img.url[index] = null;
						this.openSnackbar(res, "error");
						cb(null, index);
					}
				};

				if (verifyDimension) {
					const that = this;
					img.onload = function imgWH() {
						that.img.validation.width = this.width;
						that.img.validation.height = this.height;
						validationProcess();
					};
				} else {
					validationProcess();
				}
			} else {
				this.img.url[index] = null;
				cb(null, index);
			}
		},
		removeImg(index, cb) {
			this.img.url[index] = null;
			cb(null, index);
		},
		imgValidation(size, verifyDimension) {
			let flag = "true";
			if (!(size && handleFileSize(size, this.img.validation.size))) {
				flag = `Size should be less than ${size / 1000} KB`;
			} else if (!(allowedImgType && handleFileType(allowedImgType, this.img.validation.type))) {
				flag = `Type should be one of ${allowedImgType.join(", ")}`;
			} else if (verifyDimension && !(bannerImageWidth
				&& bannerImageHeight
				&& this.img.validation.width === bannerImageWidth
				&& this.img.validation.height === bannerImageHeight)) {
				flag = `Dimension should be ${bannerImageWidth}px x ${bannerImageHeight}px`;
			}
			return flag;
		},
	},
};

export const api = {
	methods: {
		callApi(axios, url, body, auth, file = false, fileVar) {
			return new Promise((resolve) => {
				let data = null;
				axios.post(
					url,
					generateData(body, auth, file, fileVar),
					{
						headers: generateHeader(file),
					},
				)
					.then((response) => {
						data = decryptData(response.data);
						switch (data.code) {
						case 200:
						case 201:
						case 204:
						case 205:
							this.openSnackbar(data.extra.msg, "success");
							resolve(data.body);
							break;

						case 401:
						case 422:
							this.openSnackbar(data.extra.msg, "error");
							this.$store.commit(
								"LOGOUT_SUCCESS",
							);
							this.$router.push({ name: "Login" });
							resolve();
							break;

						case 404:
							this.openSnackbar(data.extra.msg, "error");
							resolve(data.body);
							break;

						case 250:
						case 251:
						case 400:
						case 402:
						case 403:
						case 405:
						case 409:
						case 412:
						case 413:
						case 415:
						case 503:
							this.openSnackbar(data.extra.msg, "error");
							resolve();
							break;

						default:
							this.openSnackbar("Unexpected Error !!!", "error");
							resolve();
							break;
						}
					})
					.catch((error) => {
						this.openSnackbar(
							error,
							"error",
							-1,
						);
						resolve();
					});
			});
		},
	},
};

export const toursMixin = {
	data() {
		return {
			apiCall: {
				cc: false,
				tour: false,
			},
			tours: {
				countryDropDown: {},
				data: [],
				totalData: 0,
				count: 9,
				code: 200,
				search: {
					continent: null,
					country: [],
					page: 1,
					words: null,
					deals: false,
					tourType: null,
					random: null,
				},
			},
		};
	},
	methods: {
		getCCDropDown() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.apiCall.cc = false;
				this.callApi(this.$axios, "f/cc/get", {}, { apiKey: "ev-f-cc-get" }).then((response) => {
					if (response && Object.keys(response).length > 0) {
						this.tours.countryDropDown = response;
					}
					this.apiCall.cc = true;
				});
			}
		},
		reset() {
			this.tours.data = [];
			this.tours.totalData = 0;
		},
		getTours() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.apiCall.tour = false;
				this.callApi(this.$axios, "f/tour/get", this.tours.search, { apiKey: "ev-f-to-get" }).then((response) => {
					this.reset();
					if (response && response.value.length > 0) {
						this.tours.data = response.value;
						this.tours.totalData = response.totalData;
						this.tours.count = response.count;
						this.tours.code = 200;
					} else {
						this.tours.code = 404;
					}
					this.apiCall.tour = true;
				});
			}
		},
	},
};
