import CryptoJS from "crypto-js";

const onWindowResize = (isMobile, isMobileOnly) => {
	// isMobile = Mobile & Tablet (600px > < 960px)
	// isMobileOnly = Only Mobile (< 600px)
	const width = window.innerWidth;
	if (width < 600) {
		// Only Mobile
		isMobileOnly = true;
		isMobile = true;
	} else if (width >= 600 && width < 960) {
		// Mobile & tablet
		isMobileOnly = false;
		isMobile = true;
	} else {
		// Nothing
		isMobileOnly = false;
		isMobile = false;
	}
	return [isMobile, isMobileOnly];
};

// eslint-disable-next-line no-unused-vars
const encryptByDES = (string, key, ivstr) => {
	const KeyHex = CryptoJS.enc.Utf8.parse(key);
	const encrypted = CryptoJS.DES.encrypt(string, KeyHex, {
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
		iv: CryptoJS.enc.Utf8.parse(ivstr),
	});

	const hexstr = encrypted.ciphertext.toString();
	return hexstr;
};

// eslint-disable-next-line no-unused-vars
const decryptByDES = (ciphertext, key, ivstr) => {
	const keyHex = CryptoJS.enc.Utf8.parse(key);
	const decrypted = CryptoJS.DES.decrypt({
		ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
	}, keyHex, {
		iv: CryptoJS.enc.Utf8.parse(ivstr),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return decrypted.toString(CryptoJS.enc.Utf8);
};

const encyptData = (data) => {
	// Encypt Here ...
	// const dataString = encryptByDES(JSON.stringify(data), "1234567890123456", "12345678");
	const dataString = data;
	return dataString;
};

const decryptData = (data) => {
	// Decrypt Here...
	// const dataJson = JSON.parse(decryptByDES(data, "1234567890123456", "12345678"));
	const dataJson = data;
	return dataJson;
};

const generateHeader = (file = false) => {
	const headers = {
		Appkey: "ev-app",
	};
	if (file) {
		Object.assign(headers, { "Content-Type": "multipart/form-data" });
	}
	return headers;
};

const generateData = (body, auth, file, fileVar) => {
	const date = new Date();
	let data = null;
	auth.date = `${date.getFullYear()}/${date.getMonth() + 1}/${String(date.getDate())} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

	if (file) {
		data = new FormData();
		const fileType = {};
		for (let i = 0; i < Object.keys(fileVar).length; i++) {
			const varName = fileVar[i];
			fileType[varName] = {};
			const tempVarName = varName.split(".");
			let tempBody = body;
			for (let index = 0; index < tempVarName.length; index++) {
				const tempIndex = tempVarName[index];
				tempBody = tempBody[tempIndex];
			}
			for (let j = 0; j < Object.keys(tempBody).length; j++) {
				const element = tempBody[j];
				data.append(`${varName}_${j}`, element);
				fileType[varName][j] = `${varName}_${j}`;
			}
		}
		body.fileType = fileType;
		data.append("body", encyptData(body));
		data.append("auth", encyptData(auth));
		return data;
	}
	data = {
		body,
		auth,
	};
	// Encrypt here...
	return encyptData(data);
};

const handleFileSize = (allowSize, size) => {
	if (size <= allowSize) {
		return true;
	}
	return false;
};

const handleFileType = (allowedType, fileType) => {
	if (allowedType.includes(fileType)) {
		return true;
	}
	return false;
};

const getToken = () => localStorage.getItem("token");
export {
	onWindowResize,
	generateHeader,
	generateData,
	decryptData,
	encyptData,
	getToken,
	handleFileSize,
	handleFileType,
};
